import Popup from "../general/popup/Popup";
import DialogTitle from "../../newStructure/component/general/dialog/DialogTitle";
import DialogButtonContainer from "../../newStructure/component/general/dialog/DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "../../newStructure/component/general/dialog/DialogButton";
import Dialog from "../../newStructure/component/general/dialog/Dialog";
import DialogMessage from "../../newStructure/component/general/dialog/DialogMessage";

const BackOrderPrompt = ({ webProduct, desiredQuantity, desiredUom, onResponse }) => {

    const uom = desiredUom ? desiredUom : webProduct.getSelectedUom();
    const adjustedAvailable = Math.floor(webProduct.getAvailable() / uom.uomSize);
    const uomDescription = uom.name.includes("Case") ?
        "case" + (adjustedAvailable > 0 ? "s" : "")
        : uom.name.includes("Split") ? "split" + (adjustedAvailable > 0 ? "s" : "") : uom.name;
    const toBeBackOrdered = desiredQuantity - adjustedAvailable;

    const getOOSBackOrderTitle = () => {
        if(webProduct.isNotStocked()) {
            return "This product is only available on request, do you want to back order it?";
        } else {
            return "This product is currently out of stock, do you want to back order it?";
        }
    }

    const getOOSBackOrderMessage = () => {

        if(webProduct.isNotStocked()) {
            return <>This product is only available on request. If you place the stock on back order, we will order it
                in for you. Would you like to place this product on back order?</>
        } else {
            return <>There are currently only <b>{adjustedAvailable}</b> {uomDescription} available,
                would you like to place the additional <b>{toBeBackOrdered}</b> on back order?</>
        }
    }

    return (
        <Dialog>
            <DialogTitle>Secure Your Items with Back Order!</DialogTitle>
            <DialogMessage>
                <p className="back-order-message">
                    {webProduct.getAvailable() <= 0 ?
                        <>{getOOSBackOrderTitle()}</> :
                        <>{getOOSBackOrderMessage()}</>
                    }
                </p>
                <h3>Back Order? Here's How it Works:</h3>
                <p><b>Secure Your Products:</b> Lock in your position in the queue for future stock.</p>
                <p><b>Shipping:</b> Once stock becomes available, we will deliver it with your next order, or if your
                    back orders meet the minimum shipment amount, we will deliver them as a standalone delivery.</p>
            </DialogMessage>
            <DialogButtonContainer>
                <DialogButton type={DIALOG_BUTTON_TYPE.neutral} onClick={() => onResponse(false)}>Back</DialogButton>
                <DialogButton type={DIALOG_BUTTON_TYPE.positive} onClick={() => onResponse(true)}>Back
                    Order</DialogButton>
            </DialogButtonContainer>
        </Dialog>
    )
}

const CantOrderPrompt = ({webProduct, desiredQuantity, onClose}) => {

    const uom = webProduct.getSelectedUom();
    const adjustedAvailable = Math.floor(webProduct.getAvailable() / uom.uomSize);
    const uomDescription = uom.name.includes("Case") ? "case" + (adjustedAvailable > 0 ? "s" : "") : uom.name;

    return (
        <Dialog>
            <DialogTitle>Insufficient Stock</DialogTitle>
            <DialogMessage>
                {desiredQuantity > 1 ?
                    <>There are currently only <b>{adjustedAvailable}</b> {uomDescription} available. Unfortunately
                        this product cannot currently be back ordered</> :
                    <>Unfortunately you cannot currently back order this product</>
                }
            </DialogMessage>
            <DialogButtonContainer>
                <DialogButton style={{gridColumn: "2"}} onClick={onClose}>Back</DialogButton>
            </DialogButtonContainer>
        </Dialog>
    )
}

const InsufficientStockPrompt = ({webProduct, desiredQuantity, desiredUom, canBackOrder, onResponse}) => {

    return (
        <Popup>
            {canBackOrder ?
                <BackOrderPrompt
                    webProduct={webProduct}
                    desiredQuantity={desiredQuantity}
                    desiredUom={desiredUom}
                    onResponse={onResponse} /> :
                <CantOrderPrompt
                    webProduct={webProduct}
                    desiredQuantity={desiredQuantity}
                    onClose={() => onResponse(false)} />
            }
        </Popup>
    )

}

export default InsufficientStockPrompt;
