import Input from "../../../../../components/general/input/Input";
import {useRef} from "react";

const CartOrder = ({ checkout }) => {

    const ref = useRef({});
    const currentRef = checkout.getReference();
    const currentPromo = checkout.getPromotionCode();

    return (
        <>
            <div className="cart-info-split">
                <p className="cart-h">Order</p>
                <p className="cart-h">{checkout.getDocumentNo()}</p>
            </div>
            <Input
                defaultValue={currentRef}
                hint="Order Reference"
                type="text"
                className="cart-input"
                onChange={(e) => {
                    ref.current.ref = e.target.value;
                }}
                onBlur={() => checkout.dispatchOrderUpdateValue("POReference", ref.current.ref, currentRef)}
            />
            <Input
                defaultValue={currentPromo}
                hint="Promotion Code"
                className="cart-input"
                onChange={(e) => {
                    ref.current.promo = e.target.value;
                }}
                onBlur={() => checkout.dispatchOrderUpdateValue("PromotionCode", ref.current.promo, currentPromo)}
            />
        </>
    )

}

export default CartOrder;
