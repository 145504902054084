import CartAddress from "./CartAddress";

const CartConfirmationDelivery = ({ checkout }) => {

    const deliveryVia = checkout.getDeliveryViaRule();
    const deliveryText = deliveryVia === 'D' ? "Delivering to " : "Collection for ";
    const deliveryLoc = checkout.getLocationAndAddress();
    const message = deliveryText + deliveryLoc.Name;

    return (
        <div>
            <p className="cart-h">{message}</p>
            <CartAddress address={deliveryLoc.C_Location_ID} />
        </div>
    )
}

export default CartConfirmationDelivery;
