import CartConfirmationDelivery from "../info/CartConfirmationDelivery";
import CartConfirmationHeader from "../info/CartConfirmationHeader";
import CartConfirmationLines from "../info/CartConfirmationLines";
import CartConfirmationPrint from "../info/CartConfirmationPrint";
import CartSummary from "../info/CartSummary";

const CartConfirmation = ({ checkout }) => {

    return (
        <div className="cart-container">
            <div className="cart-header">
                <p className="cart-h1">Order Confirmation</p>
            </div>

            <div className="cart-content">
                <div className="cart-section">
                    <CartConfirmationDelivery checkout={checkout}/>
                </div>

                <div className="cart-full-width cart-section">
                    <CartConfirmationHeader checkout={checkout}/>
                </div>

                <div className="cart-full-width">
                    <p className="cart-h2">Lines</p>
                    <CartConfirmationLines checkout={checkout}/>
                </div>

                <div className="cart-column"/>
                <div className="cart-column">
                    <div className="cart-section">
                        <p className="cart-h2">Summary</p>
                        <CartSummary checkout={checkout}/>
                    </div>
                    <div className="cart-section">
                        <p className="cart-h2">Print</p>
                        <CartConfirmationPrint checkout={checkout} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartConfirmation;
