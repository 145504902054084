import PageLayout from "../../../../components/page/PageLayout";
import CartReview from "../cart/stage/CartReview";
import {useState} from "react";
import CartCheckout from "../cart/stage/CartCheckout";
import DialogLoading from "../../general/dialog/DialogLoading";
import useCheckout from "../cart/cart/useCheckout";
import CartConfirmation from "../cart/stage/CartConfirmation";
import CartEmpty from "../cart/stage/CartEmpty";

const REVIEW = "R";
const CHECKOUT = "C";
const AFTER_CHECKOUT = "AF";

const EmptySwitcher = ({isEmpty, children}) => {

    if(isEmpty) {
        return <CartEmpty />
    } else {
        return (
            <>
                {children}
            </>
        )
    }


}

const LoadingCart = ({ loading, children }) => {
    if(loading) {
        return (
            <div className="page-min-height">
                <DialogLoading loading={true} title={"Loading Cart"} />
            </div>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}

const ThePage = () => {

    const checkout = useCheckout();
    const orderIsComplete = checkout.isOrderComplete();

    const [ cartState, setCartState ] = useState(orderIsComplete ? AFTER_CHECKOUT : REVIEW);

    return (
        <LoadingCart loading={checkout.isCartLoading()}>
            {orderIsComplete &&
                <CartConfirmation checkout={checkout} />
            }
            {!orderIsComplete &&
                <EmptySwitcher isEmpty={checkout.isCartEmpty()}>
                    {cartState === REVIEW &&
                        <CartReview checkout={checkout} onNext={() => setCartState(CHECKOUT)} />
                    }
                    {cartState === CHECKOUT &&
                        <CartCheckout checkout={checkout} />
                    }
                </EmptySwitcher>
            }
        </LoadingCart>
    )
}

const PageCartReview = () => {

    return (
        <PageLayout requireSignIn={true}>
            <ThePage />
        </PageLayout>
    )
}

export default PageCartReview;
