import React from "react";
import { format } from "date-fns";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";
import './header.css'
import LinkText from "../../../navigation/link/LinkText";
import useIsAccountPage from "../../layout/structure/useIsAccountPage";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import usePrint from "../../../../../components/general/output/usePrint";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import LinkTextButton from "../../../navigation/link/LinkTextButton";

const HeaderOrder = ({order, showAmend, onAmend, canAmend}) => {

    const goTo = useIsAccountPage() ? "account" : "vendor";
    const orderType = order.IsSOTrx ? "Sales Order" : "Purchase Order";
    const jsDateOrdered = idempiereDate.getDate(order.DateOrdered);
    const formattedOrdered = format(jsDateOrdered, "dd/MM/yy");
    const displayPrice = formatDisplayPrice(order.GrandTotal);

    const printUrl = request.print(api.MODEL.C_ORDER, order?.id).buildRequest(env.API_URL)
    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({url: printUrl});

    const documentNo = !order.IsSOTrx && order.POReference ? order.POReference : order.DocumentNo;

    return (
        <div className="acct-line">
            <p className="acct-line-h1">{orderType}</p>
            <div className="header-detail">
                <div>
                    <p className="acct-line-h2">Order No</p>
                    <p className="acct-line-h3">{documentNo}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Ordered</p>
                    <p className="acct-line-h3">{formattedOrdered}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Location</p>
                    <p className="acct-line-h3">{order.C_BPartner_Location_ID.identifier}</p>
                </div>
                <div>
                    <p className="acct-line-h2 num">Total</p>
                    <p className="acct-line-h3 num">{displayPrice}</p>
                </div>
            </div>
            <div className="header-actions">
                <LinkText
                    className="header-link"
                    href={"/" + goTo + "/orders/order/" + order.DocumentNo}
                >
                    See lines
                </LinkText>
                <LoadingSwitcher skeletonStyle={{width: 100, height: "1em"}} loading={loadingPDF}>
                    {showAmend &&
                        <>
                            {canAmend &&
                                <LinkTextButton
                                    className="header-link"
                                    onClick={() => onAmend(order)}
                                >
                                    Amend Order
                                </LinkTextButton>
                            }
                            {!canAmend &&
                                <p className="acct-line-h3">Cannot Amend Order</p>
                            }
                        </>
                    }
                    {!showAmend &&
                        <LinkTextButton
                            className="header-link"
                            onClick={fetchAndPrintIt}
                        >
                            Print Order
                        </LinkTextButton>
                    }
                </LoadingSwitcher>
            </div>
        </div>
    )
}

export default HeaderOrder;
