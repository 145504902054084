import useMinSpend from "../cart/useMinSpend";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";

const MinSpend = () => {

    const { minSpend, loading } = useMinSpend();

    const standardSpend = minSpend?.standard?.X_Freeshipment_Amount;
    const categorySpend = minSpend?.category?.map(c =>
        `£${c.X_Freeshipment_Amount.toFixed(2)} on ${c.Name}`)

    const categoryMessage = categorySpend?.length > 0 ?
        ",or a minimum of " + categorySpend.join("; ") : "";

    return (
        <LoadingSwitcher loading={loading}>
            {standardSpend &&
                <p className="cart-delivery-msg">
                    To qualify for <span>free delivery</span> on this order, spend a minimum of
                    £{standardSpend.toFixed(2)} on Stock {categoryMessage}
                </p>
            }
        </LoadingSwitcher>
    )
}

const CartDeliveryMessage = ({checkout}) => {

    const charges = checkout.getCharges();
    const hasDeliveryCharge = Boolean(charges.find(e => e.C_Charge_ID.id === 1000002));

    if (!hasDeliveryCharge) {
        return <p>Free Delivery</p>
    } else {
        return <MinSpend />
    }

}

export default CartDeliveryMessage;
