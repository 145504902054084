import {Button} from "../../../../../components/general/input/Button";
import {useNavigate} from "react-router-dom";

const CartEmpty = () => {

    const navigate = useNavigate();
    const onClick = () => {
        navigate("/promotion");
    }

    return (
        <div className="cart-container">
            <div className="cart-header">
                <p className="cart-h1">Your Cart</p>
            </div>
            <div className="cart-content">
                <div className="cart-section">
                    <p className="cart-h2">Empty Cart</p>
                    <p className="cart-line-h2">Your cart is currently empty.</p>
                    <p />
                    <Button onClick={onClick}>View Promotions </Button>
                </div>
            </div>
        </div>
    )
}

export default CartEmpty;
