import {Button} from "../../../../../components/general/input/Button";

const CartCheckoutButton = ({ checkout }) => {

    const blockers = checkout.getBlockers();
    const canCheckout = blockers.length === 0;

    const onClick = () => {
        if(canCheckout) {
            checkout.checkoutOrder();
        }
    }

    return  (
        <>
            {blockers.map((e,i) =>
                <p key={i} className="cart-blocker">{e}</p>
            )}
            <Button
                onClick={onClick}
                className={ canCheckout ? "" : "inactive" }
            >
                Checkout
            </Button>
        </>
    )
}

export default CartCheckoutButton;
