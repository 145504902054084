import useUsuals from "../../../services/state/product/useUsuals";
import PageLayout from "../../../../components/page/PageLayout";
import OrderEntryCategory from "../../orderEntry/OrderEntryCategory";
import MapUtil from "../../../logic/collection/mapUtil";
import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../../../../components/general/input/Button";

const compareLists = (thisList, thatList) => {
    const thisTopProduct = thisList[0].purchase_instance_count;
    const thatTopProduct = thatList[0].purchase_instance_count;

    if(thisTopProduct !== thatTopProduct) {
        return thatTopProduct - thisTopProduct;
    }

    return thatList.length - thisList.length;
}

const getProductsByCategory = (usuals) => {
    if(!usuals) return null;

    const productLists = MapUtil.filterValues(usuals, (prods) => prods.length > 0 && prods[0].M_Product_ID?.id > 0);
    productLists.sort(compareLists)
    return productLists;
}

const NoProducts = () => {

    const navigate = useNavigate();

    return (
        <div className="page-content" style={{textAlign: "left"}}>
            <p className="page-h2" style={{marginTop: 30}}>No Products</p>
            <p style={{maxWidth: 600, color: "#5a5a5d", fontWeight: 500}}>
                This page displays items you've ordered within the last few months for quick reordering.
                As we don't have any orders within that timeframe for your account,
                you'll need to browse our product catalog below to place a new order.
                Once you place an order, your recent purchases will be displayed here for easy reordering.</p>
            <Button
                onClick={() => navigate("/promotion")}
                style={{maxWidth: 600}}
            >
                View Our Latest Deals
            </Button>
        </div>
    )
}

const PageOrderEntry = () => {

    const {
        loading,
        usuals
    } = useUsuals();

    const [ expandedID, setExpandedID ] = useState(0);
    const isExpanded = (list) => {
        return list[0].M_Product_Category_ID.id === expandedID;
    }
    const setExpanded = (categoryID) => {
        if(expandedID === categoryID) {
            setExpandedID(0);
        } else {
            setExpandedID(categoryID);
        }
    }

    const productsByCategory = useMemo(() => getProductsByCategory(usuals), [usuals]);

    return (
        <PageLayout
            requireSignIn={true}
        >
            <div className="page-content page-min-height">
                {productsByCategory?.length > 0 && productsByCategory.map((products,i) =>
                    (products?.length > 0 && products[0].M_Product_ID?.id > 0 &&
                        <OrderEntryCategory
                            key={i}
                            productList={products}
                            expanded={isExpanded(products)}
                            setExpanded={setExpanded}
                        />
                    )
                )}
                {!productsByCategory && loading &&
                    <p>Loading</p>
                }
                {!loading && (!productsByCategory || productsByCategory.length === 0) &&
                    <NoProducts />
                }
            </div>
        </PageLayout>
    )
}

export default PageOrderEntry;
