
const AddressLine = ({ line }) => {
    if(!line) return;

    return (
        <p className="cart-address-line">{line}</p>
    )
}

const CartAddress = ({ address }) => {
    if(!address) return <></>;

    return (
        <>
            <AddressLine line={address.Address1} />
            <AddressLine line={address.Address2} />
            <AddressLine line={address.Address3} />
            <AddressLine line={address.Address4} />
            <AddressLine line={address.City} />
            <AddressLine line={address.Postal} />
        </>
    )
}

export default CartAddress;
