import Checkbox from "../Checkbox";
import './radio.css'

const Radio = ({ isSelected, onSelected, title, children }) => {

    return (
        <div className="radio" onClick={onSelected}>
            <Checkbox
                onCheckedChange={onSelected}
                isChecked={isSelected}
                alwaysUseIsChecked
            />
            {title &&
                <p className="radio-title">{title}</p>
            }
            {children}
        </div>
    )
}

export default Radio;
