import CountDownClock from "../general/time/CountDownClock";
import React from "react";
import './deliveryCountDown.css'
import {idempiereDate} from "../../res/dataServices/idempiereDate";
import useDeliveryCutoff from "../state/account/useDeliveryCutoff";
import useBusinessLocations from "../../newStructure/services/state/account/useBusinessLocations";
import useSession from "../../newStructure/services/state/session/useSession";
import useDeliveryDates from "../../newStructure/services/state/delivery/useDeliveryDates";

const DeliveryCountDown = () => {

    const { getDeliveryDisplay } = idempiereDate;
    const { locations } = useBusinessLocations();
    const cutoff = useDeliveryCutoff();

    const session = useSession();
    const { availableDates, loading } = useDeliveryDates({ bPartnerID: session?.sessionData?.bPartnerID, top: 3 });

    if(!locations) return;

    const pickUpOnly = !(availableDates?.length > 0);
    const nextLoc = !pickUpOnly ? availableDates?.[0]?.C_BPartner_Location_ID?.identifier : null;

    if(!nextLoc || loading) {
        // TODO add in collection count down timer
        return;
    }

    const nextDeliveryDay = availableDates?.length > 0 ? new Date(availableDates[0].available_date) : null;
    const now = new Date();
    const hasNextDayDelivery = now.getFullYear() === nextDeliveryDay.getFullYear() &&
        now.getMonth() === nextDeliveryDay.getMonth() &&
        now.getDate() === nextDeliveryDay.getDate();


    return (
        <div className="delivery-count-down-container">
            <div className="delivery-count-down">
                {hasNextDayDelivery ?
                    <>
                        <p className="delivery-count-down-header">For next day {pickUpOnly ? "collection" : "delivery"}*, order within</p>
                        <CountDownClock targetDate={cutoff} />
                    </> :
                    <>
                        <p className="delivery-count-down-header">Next day {pickUpOnly ? "collection" : "delivery"} not available</p>
                        {nextDeliveryDay &&
                            <p>Next {pickUpOnly ? "collection" : "delivery"} day:<br/>
                                {getDeliveryDisplay(nextDeliveryDay)}</p>
                        }
                    </>
                }
                <p className="delivery-count-down-note">*based on delivery to {nextLoc}</p>
            </div>
        </div>
    )

}

export default DeliveryCountDown;
