import CartDeliveryNote from "../info/CartDeliveryNote";
import CartSummary from "../info/CartSummary";
import CartDeliveryMessage from "../info/CartDeliveryMessage";
import CartOrder from "../input/CartOrder";
import CartDeliveryLocation from "../input/CartDeliveryLocation";
import CartPayment from "../input/CartPayment";
import CartDeliveryMethod from "../input/CartDeliveryMethod";
import LoadingDialog from "../../../general/dialog/DialogLoading";
import CartDeliveryDate from "../input/CartDeliveryDate";
import CartCheckoutButton from "../input/CartCheckoutButton";
import CartCheckoutError from "../info/CartCheckoutError";
import CartPaymentProcessor from "../input/CartPaymentProcessor";

const CartCheckout = ({ checkout }) => {

    return (
        <div className="cart-container">
            <CartPaymentProcessor checkout={checkout} />
            <CartCheckoutError checkout={checkout} />
            <LoadingDialog title="Completing Checkout" loading={checkout.isCheckoutLoading()} />
            <LoadingDialog title="Updating Order" loading={checkout.isOrderLoading()} />
            <div className="cart-header">
                <p className="cart-h1">Checkout</p>
                <CartDeliveryNote
                    orderLocation={checkout.getBPartnerLocation()}
                    availableDates={checkout.getAvailableDates()}
                    loading={checkout.isDeliveryDatesLoading()}
                    forPickUp={checkout.getDeliveryViaRule() === 'P'}
                />
            </div>
            <div className="cart-content">
                <div className="cart-column">
                    <div className="cart-section">
                        <p className="cart-h2">Order</p>
                        <CartOrder checkout={checkout} />
                    </div>
                    <div className="cart-section">
                        <p className="cart-h2">Delivery</p>
                        <CartDeliveryLocation checkout={checkout} />
                        <CartDeliveryMethod  checkout={checkout} />
                        <CartDeliveryDate checkout={checkout} />
                    </div>
                    <div className="cart-section">
                        <p className="cart-h2">Payment</p>
                        <CartPayment checkout={checkout} />
                    </div>
                </div>

                <div className="cart-column">
                    <div className="cart-section">
                        <p className="cart-h2">Summary</p>
                        <CartSummary checkout={checkout} />
                    </div>
                    <div className="cart-delivery">
                        <p className="cart-h2">Delivery</p>
                        <CartDeliveryMessage checkout={checkout} />
                    </div>
                    <p className="cart-h2">Checkout</p>
                    <CartCheckoutButton
                        checkout={checkout}
                    />
                </div>
            </div>
        </div>
    )
}

export default CartCheckout;
