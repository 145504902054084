import React from "react";
import {format} from "date-fns";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";
import LinkText from "../../../navigation/link/LinkText";
import './header.css'
import useIsAccountPage from "../../layout/structure/useIsAccountPage";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import usePrint from "../../../../../components/general/output/usePrint";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import LinkTextButton from "../../../navigation/link/LinkTextButton";


const InvoiceHeader = ({ invoice }) => {

    const goTo = useIsAccountPage() ? "account" : "vendor";
    const invType       = invoice.DocBaseType.identifier;
    const documentNo    = invoice.DocumentNo;
    const jsDue         = idempiereDate.getDate(invoice.DueDate);
    const due           = format(jsDue, "dd/MM/yy");
    const displayPrice  = formatDisplayPrice(invoice.GrandTotal, true);
    const openBalance   = invoice.web_invoice_open_balance_v?.[0]?.openbalance;
    const ref           = invoice.POReference;

    const printUrl = request.print(api.MODEL.C_INVOICE, invoice?.id).buildRequest(env.API_URL)
    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({url: printUrl});

    return (
        <div className="acct-line">
            <p className="acct-line-h1">{invType}</p>
            <div className="header-detail">
                <div>
                    <p className="acct-line-h2">Invoice Number</p>
                    <p className="acct-line-h3">{documentNo}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Ref</p>
                    <p className="acct-line-h3">{ref ? ref : ""}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Due Date</p>
                    <p className="acct-line-h3">{due}</p>
                </div>
                <div>
                    <p className="acct-line-h2 num">Invoice Total</p>
                    <p className="acct-line-h3 num">
                        {displayPrice}
                    </p>
                </div>
                {openBalance != null &&
                    <div>
                        <p className="acct-line-h2 num">Open Balance</p>
                        <p className="acct-line-h3 num">
                            {formatDisplayPrice(openBalance, true)}
                        </p>
                    </div>
                }
            </div>
            <div className="header-actions">
                <LinkText
                    className="header-link"
                    href={"/" + goTo + "/orders/invoice/" + documentNo}
                >
                    See payments/ lines
                </LinkText>
                <LoadingSwitcher skeletonStyle={{width: 100, height: "1em"}} loading={loadingPDF}>
                    <LinkTextButton
                        className="header-link"
                        onClick={fetchAndPrintIt}
                    >
                        Print Invoice
                    </LinkTextButton>
                </LoadingSwitcher>
            </div>
        </div>
    )
}

export default InvoiceHeader;
