import {updateOrder} from "../../../../../../redux/action/cartActions";


const getCartUpdateFunctions = (session, cart, dispatch, functions) => {

    const updateFunctions = {};

    updateFunctions.dispatchOrderUpdateValue = (field, val, current) => {
        if(val === current) return;
        const toChange = {[field]: val};
        const orderID = cart.id;
        dispatch(updateOrder({session, orderID, toChange}));
    }

    updateFunctions.dispatchOrderUpdate = (toChange) => {
        const orderID = cart.id;
        dispatch(updateOrder({session, orderID, toChange}));
    }

    updateFunctions.updateAddress = (location, onNoChange) => {
        const id = location.id;
        if(id === cart?.order?.C_BPartner_Location_ID?.id) {
            onNoChange();
        } else {
            const toChange = {"C_BPartner_Location_ID": {id}};
            updateFunctions.dispatchOrderUpdate(toChange);
        }
    }

    updateFunctions.updateDeliveryVia = (id) => {
        if(id !== functions.getDeliveryViaRule()) {
            const toChange = {"DeliveryViaRule" : { id }};
            updateFunctions.dispatchOrderUpdate(toChange);
        }
    }

    updateFunctions.updateDatePromised = (date, onNoChange) => {
        const datePromised = functions.getDatePromised();
        console.log(datePromised, date);
        if(datePromised && datePromised.getDate() === date.getDate() && datePromised.getFullYear() === date.getFullYear()) {
            onNoChange();
            return;
        }
        if(date !== null) date.setHours(12); // Stops time zone from affecting the date
        const toChange = {"DatePromised": date};
        updateFunctions.dispatchOrderUpdate(toChange);
    }

    updateFunctions.updatePaymentType = (id) => {
        if(id !== functions.getPaymentType()) {
            const toChange = {"PaymentRule" : { id }}
            dispatch(updateOrder({session, orderID: cart.id, toChange}))
        }
    }

    return updateFunctions;
}

export default getCartUpdateFunctions;
