import useCart from "../../../../../components/state/useCart";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import {useMemo, useState} from "react";
import useDeliveryDates from "../../../../services/state/delivery/useDeliveryDates";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";
import {useDispatch, useSelector} from "react-redux";
import useSession from "../../../../services/state/session/useSession";
import {checkout, prepareOrder} from "../../../../../redux/action/cartActions";
import useBusinessLocations from "../../../../services/state/account/useBusinessLocations";
import DateUtil from "../../../general/calendar/dateUtil";
import useBusinessPartner from "../../../../services/state/account/useBusinessPartner";
import {paymentOptions} from "../../../../../components/checkout/form/InputPayment";
import useOverPayment from "./useOverpayment";
import usePreviousPayments from "../../../../services/state/checkout/usePreviousPayments";
import getCartFunctions from "./logic/cartFunctions";
import getCartUpdateFunctions from "./logic/cartUpdateFunctions";
import {paymentValidator} from "../../../../../components/payment/paymentValidator";
import {clearComplete} from "../../../../../redux/slice/cartSlice";
import {runInitialPaymentStep} from "../../../../../components/payment/PaymentProcessor";
import {trackPaymentType} from "./logic/checkoutAnalytics";

const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 60);

const getCardPaymentInfo = (session, orderDetail, cardInput, amount, overPayment) => {
    const order = orderDetail.order;
    return {
        ...cardInput,
        currency: 'GBP',
        overPayment,
        amount: amount.toFixed(2),
        locationID: order["C_BPartner_Location_ID"].id,
        bPartnerID: session.bPartnerID,
        userID: session.userID,
        orderID: orderDetail.id,
    };
}

const getPaymentOptions = (businessPartner, onlyCard) => {
    if(!businessPartner || onlyCard) {
        return [paymentOptions.K];
    } else if(businessPartner.getPaymentRule()?.id === "D" && !(businessPartner.getCreditStatus() === "S")) {
        return [paymentOptions.D, paymentOptions.K];
    } else if(businessPartner.getPaymentRule()?.id === "K" || businessPartner.getCreditStatus() === "S") {
        return [paymentOptions.K];
    } else {
        return [paymentOptions.T, paymentOptions.K];
    }
};

const getOrderedLines = (cartLines) => {
    if(!cartLines) return [];
    const lines = Object.values(cartLines);
    lines.sort((a,b) => a.Line - b.Line);
    return lines;
}

const getOrderedPurchaseLines = (cart) => {
    if(!cart.purchasedLines) return [];
    const lines = Object.values(cart.purchasedLines);
    lines.sort((a,b) => a.Line - b.Line);
    return lines;
}

const isDeliveryDateOk = (functions, availableDates) => {
    if(!availableDates) return false;

    const datePromised = functions.getDatePromised();
    if(!datePromised) return false;

    const availableDate = availableDates.find(d => DateUtil.isSameDay(new Date(d.available_date), datePromised));
    return availableDate != null;
}

const validateDeliveryDate = (functions, serverFunctions, availableDates) => {
    if(!availableDates || availableDates.length === 0) return;

    if(!isDeliveryDateOk(functions, availableDates)) {
        const nextDate = new Date(availableDates[0].available_date);
        serverFunctions.updateDatePromised(nextDate, () => {});
    }
}

const getCheckoutBlockers = (functions, availableDates, cardDetails) => {
    const blockers = [];

    if(!functions.getBPartnerLocationID() > 0) {
        blockers.push("Delivery address required");
    } else if(!functions.getDeliveryViaRule()) {
        blockers.push("Delivery type required");
    } else if(!isDeliveryDateOk(functions, availableDates)) {
        blockers.push("Delivery date required");
    }

    if(functions.getPaymentType() === 'K') {
        if(!cardDetails) {
            blockers.push("Card details required");
        } else if(!cardDetails.firstName || !cardDetails.lastName) {
            blockers.push("Full name on card is missing");
        } else if(paymentValidator.validateCardNumber(cardDetails.cardNumber)) {
            blockers.push("Card number is not valid");
        } else if(paymentValidator.validateExpiry(cardDetails.expireMonth + "/" + cardDetails.expireYear)) {
            blockers.push("Card expiry is not valid");
        } else if(paymentValidator.validateCvv(cardDetails.cvv)) {
            blockers.push("Card cvv not valid");
        }

    }



    return blockers;
}

const useCheckout = () => {

    const dispatch = useDispatch();
    const { sessionData: session } = useSession();

    const { cart } = useCart();
    const [ cardDetails, setCardDetails ] = useState();

    // reload cart on first load
    useValueChangeListener(cart.reloadCart, [1],[0]);

    const { paymentResult, error: paymentError } = useSelector(
        state => state.session.payment
    );

    const completeOrder = () => dispatch(checkout({session, orderID: cart.id}));
    useValueChangeListener(() => {
        if(paymentResult && paymentResult.success) {
            completeOrder();
        }
    }, [paymentResult]);

    const orderedLines = useMemo(() => getOrderedLines(cart?.lines), [cart?.lines]);

    const functions = getCartFunctions(cart);

    const bPartnerLocationID = functions.getBPartnerLocationID();

    const {
        availableDates,
        loading : isDeliveryDatesLoading
    } = useDeliveryDates({ bPartnerLocationID, maxDate, forPickUp: functions.getDeliveryViaRule() === 'P' });
    const {
        loading: isLocationsLoading,
        locations
    } = useBusinessLocations();
    const {
        businessPartner,
        loading: isBPLoading
    } = useBusinessPartner();

    const overPaymentInfo = useOverPayment();
    const previousPayments = usePreviousPayments({orderID: cart.id});
    const charges = cart.charges ? Object.values(cart.charges) : [];
    const overPayment = overPaymentInfo.requiresAdditionalPayment
        ? overPaymentInfo.calculateOverpayment(cart?.order?.GrandTotal) : 0;
    const orderTotal = Math.max((cart?.order?.GrandTotal + overPayment) - previousPayments.total, 0);
    const onlyCard = previousPayments.total > 0;

    if(overPayment > 0) {
        charges.push({
            C_Charge_ID: {Name: "Overpayment for overdue balance"},
            LineNetAmt: overPayment,
        });
    }
    if(previousPayments.total > 0) {
        charges.push({
            C_Charge_ID: {Name: "Previous Payments"},
            LineNetAmt: previousPayments.total * -1
        })
    }

    const dataFunctions = {};
    dataFunctions.getAvailableDates = () => availableDates;
    dataFunctions.isDeliveryDatesLoading = () => isDeliveryDatesLoading;
    dataFunctions.getLocations = () => locations;
    dataFunctions.isLocationsLoading = () => isLocationsLoading;
    dataFunctions.getLocationAndAddress = () => {
        const locID = functions.getBPartnerLocationID();
        return locations?.find((loc) => loc.id === locID)
    }
    dataFunctions.isBusinessPartnerInfoLoading = () => isBPLoading;
    dataFunctions.getPaymentOptions = () => getPaymentOptions(businessPartner, onlyCard);
    dataFunctions.getCharges = () => charges;
    dataFunctions.getPaymentError = () => {
        if(paymentResult && paymentResult.declined) {
            return ["Payment Declined", "We could not accept payment. Please try a different payment method"]
        } else if(paymentError) {
            return ["Payment Issue", "Message from the server: " + paymentError]
        }
    }
    functions.getTotalPrice = () => formatDisplayPrice(orderTotal);

    const serverFunctions = getCartUpdateFunctions(session, cart, dispatch, functions);

    const deliveryDateDeps = [availableDates];
    useValueChangeListener(() => validateDeliveryDate(functions, serverFunctions, availableDates), deliveryDateDeps, []);

    const getBlockers = () => getCheckoutBlockers(functions, availableDates, cardDetails);

    const afterPrepare = () => {
        const paymentRule = functions.getPaymentType();
        trackPaymentType(paymentRule, cardDetails);

        if(paymentRule === 'K' && orderTotal > 0) {
            const paymentDetails = getCardPaymentInfo(session, cart, cardDetails, orderTotal, overPayment);
            dispatch(clearComplete());
            runInitialPaymentStep(dispatch, session, paymentDetails);

        } else {
            dispatch(clearComplete());
            completeOrder();
        }
    }

    const checkoutOrder = () => dispatch(prepareOrder({session, orderID: cart.id, afterPrepare}));

    return {
        lines: orderedLines,
        ...functions,
        ...dataFunctions,
        ...serverFunctions,
        setCardDetails,
        getBlockers,
        checkoutOrder,
        getPurchasedLines: () => getOrderedPurchaseLines(cart)
    }
}

export default useCheckout;
