
import {useDispatch, useSelector} from "react-redux";
import {getCart, getOrderDetails} from "../../redux/action/cartActions";
import {clearCart} from "../../redux/slice/cartSlice";
import {paymentValidator} from "../payment/paymentValidator";
import {isSameDay} from "date-fns";
import MapUtil from "../../newStructure/logic/collection/mapUtil";
import useSession from "../../newStructure/services/state/session/useSession";
import useValueChangeListener from "../../newStructure/services/state/general/useValueChangeListener";

const useCart = () => {

    const dispatch = useDispatch();
    const { isSignedIn, sessionData : session } = useSession();

    const { orderID, order, lines, charges, loading, error } = useSelector(
        state => state.session.cart
    )

    const fetchOrderLines = () => {
        return !lines && !loading["cart"] && !error["cart"] && isSignedIn;
    }
    const fetchOrderDetail = () => {
        return orderID > 0 && !loading["cart"] && lines
            && (!order && !loading["order"] && !error["order"])
            && isSignedIn;
    }

    if (fetchOrderLines()) {
        dispatch(getCart({session}));
    }
    if (fetchOrderDetail()) {
        dispatch(getOrderDetails({session, orderID}));
    }

    const reloadCart = () => {
        if(isSignedIn) {
            dispatch(clearCart());
            dispatch(getCart({session}));
            // const afterPrepare = () => dispatch(getCart({session}));
            // dispatch(prepareOrder({session, orderID, afterPrepare}));
        }
    }

    const isProduct = (line) => {
        return line["M_Product_ID"]?.id > 0;
    }

    const productLines = lines ? {} : null;
    if(lines) {
        for(const line of Object.values(lines)) {
            if(isProduct(line)) productLines[line.id] = line;
        }
    }

    useValueChangeListener(reloadCart, [isSignedIn, session?.refreshToken]);


    const cartInfo = getCartInfo(order, productLines);
    const isComplete = cartInfo.isComplete();

    const cart = {
        id: orderID,
        order,
        lines : isComplete ? [] : productLines,
        purchasedLines: isComplete ? productLines : [],
        charges,
        loading,
        error,
        loadingCart: loading["cart"],
        loadingOrder: loading["order"],
        errorCart: error["cart"],
        errorOrder: error["order"],
        reloadCart,
        ...cartInfo
    }

    if(isSignedIn) return { cart };
    else return { cart: {} };
}

const getCartInfo = ( order, lines ) => {

    const getAddress = (locations) => {
        const bpLocation = order["C_BPartner_Location_ID"];
        if (!bpLocation || !locations) return null;

        const bpLocID = bpLocation.id;
        for(const loc of locations) {
            if(loc.id === bpLocID) {
                return loc;
            }
        }
        return null;
    }

    const getDatePromised = () => {
        const dateStr = order.DatePromised;
        if(dateStr) {
            const dateParts = dateStr.split("-")
            return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        } else {
            return null;
        }
    }

    const isForDelivery = () => order.DeliveryViaRule && order.DeliveryViaRule.id === "D";

    const getCronForAddress = (address) => {
        if(!address) return undefined;

        const addressPreference = address["X_DeliveryPreference"];
        if(addressPreference) return addressPreference;

        const salesRegion = address["C_SalesRegion_ID"];
        if(salesRegion) {
            const prefArr = salesRegion["x_deliveryPreference"];
            if(prefArr) return prefArr[0]["X_DeliveryPreference"];
        }
        return undefined;
    }

    const getDeliveryCron = (address) =>  isForDelivery() ? getCronForAddress(address) : "0 0 * * 1-5";

    const isValidDeliveryDate = (availableDates, datePromised, cutOffHours, cutOffMins) => {
        if(datePromised === null || datePromised === undefined) return false;
        if(!availableDates || availableDates.length === 0) return false;

        const now = new Date();
        if(isSameDay(datePromised, now) && (
            now.getHours() > cutOffHours || (
                now.getHours() === cutOffHours && now.getMinutes() >= cutOffMins
            ))) {
            return false;
        }

        for(const date of availableDates) {
            if(isSameDay(date, datePromised)) {
                return true;
            }
        }
        return false;
    }

    const locationsSet = () => order["C_BPartner_Location_ID"] && order["C_BPartner_Location_ID"]?.id > 0

    const deliverySet = () => order.DeliveryViaRule &&
        order.DeliveryViaRule.id && order.DatePromised &&
        new Date(order.DatePromised) > new Date();

    const paymentSet = (cardInput, onlyCard) => order.PaymentRule &&
        (!onlyCard || order.PaymentRule.id === 'K') &&
        (order.PaymentRule.id !== 'K' || paymentValidator.validateCard(cardInput).ok);

    const isCheckoutReady = (cardInput, locations, cutoff, additionalBlocker, onlyCard) => {
        return locationsSet()
            && deliverySet()
            && paymentSet(cardInput, onlyCard)
            // && isValidDeliveryDate(getAddress(locations), getDatePromised(),
            //     cutoff?.getHours(), cutoff?.getMinutes())
            && !additionalBlocker
            ;
    }

    const getCheckoutBlockers = (cardInput, locations, cutoff, onlyCard) => {
        return {
            locationIsSet: locationsSet(),
            deliveryMethodSet: order.DeliveryViaRule && order.DeliveryViaRule.id,
            paymentSet: paymentSet(cardInput, onlyCard),
            deliveryDateSet: order.DatePromised && new Date(order.DatePromised) > new Date(),
            deliveryDateOk: isValidDeliveryDate(getAddress(locations), getDatePromised(),
                cutoff.getHours(), cutoff.getMinutes())
        }
    }

    const isComplete = () => {
        try {
            return order?.DocStatus.id === 'CO';
        } catch (e) {
            console.log("e", order);
            throw Error(e);
        }
    }

    // const getGuidePrice = () =>{
    //     for(const line of lines) {
    //         console.log(line);
    //     }
    // }
    // getGuidePrice();

    const getProductTotal = () => {
        if (!lines) return;
        return MapUtil.reduce(lines, (k, v, total) => total + v.LineNetAmt, 0);
    }

    return {
        isCheckoutReady,
        getAddress,
        isValidDeliveryDate,
        getDatePromised,
        isForDelivery,
        getDeliveryCron,
        getCronForAddress,
        isComplete,
        getCheckoutBlockers,
        getProductTotal
    }
}

export default useCart;
