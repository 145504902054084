import useWebProduct from "../../../../../components/state/useWebProduct";

const CartConfirmationLine = ({ cartLine }) => {
    const { webProduct } = useWebProduct({orderLineID: cartLine.id, product: cartLine.M_Product_ID});

    return (
        <>
            <div className="cart-confirm-line-grid">
                <p className="cart-line-h2">{webProduct.getValue()}</p>
                <p className="cart-line-h2">{webProduct.getName()}</p>
                <p className="cart-line-h2 r">{webProduct.getQuantityEntered()}</p>
                <p className="cart-line-h2">{webProduct.getSelectedUom().name}</p>
                <p className="cart-line-h2 r">£{webProduct.getUomUnitPrice().toFixed(2)}</p>
                <p className="cart-line-h2 r">£{webProduct.getCalculatedLinePrice().toFixed(2)}</p>
            </div>
        </>
    )
}

const CartConfirmationLines = ({checkout}) => {

    const lines = checkout.getPurchasedLines();

    return (
        <>
            <div className="cart-confirm-line-grid">
                <p className="cart-h">Code</p>
                <p className="cart-h">Description</p>
                <p className="cart-h">Qty</p>
                <p className="cart-h">Per</p>
                <p className="cart-h">Price</p>
                <p className="cart-h">Amount</p>
            </div>
            {lines.map((l,i) =>
                <CartConfirmationLine key={i} cartLine={l} />
            )}
        </>
    )
}

export default CartConfirmationLines;
