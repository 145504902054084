import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import DateUtil from "../../../general/calendar/dateUtil";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";

const CartDeliveryNote = ({ orderLocation, availableDates, loading, forPickUp }) => {

    const orderLocationName = orderLocation?.Name;

    const getDisplayDate = () => {
        const dateStr = availableDates[0].available_date;
        const date = idempiereDate.getDate(dateStr);
        return DateUtil.getDisplayDate(date);
    }

    const message = forPickUp ? "Next date for " : "Next delivery date for delivery to ";

    return (
        <div className="cart-delivery-note">
            <LoadingSwitcher loading={loading}>
                {availableDates && availableDates.length > 0 &&
                    <>
                        <p className="cart-note">{message}{availableDates[0].C_BPartner_Location_ID?.identifier}:
                        </p>
                        <p className="cart-note">{getDisplayDate()}</p>
                    </>
                }
                {(!availableDates || availableDates.length === 0) && orderLocationName &&
                    <>
                        <p className="cart-note">No delivery to {orderLocationName}</p>
                    </>
                }
            </LoadingSwitcher>
        </div>
    )
}

export default CartDeliveryNote;
