import {useSelector} from "react-redux";
import useBusinessPartner from "../../../../services/state/account/useBusinessPartner";
import useAuthRequest from "../../../../services/state/request/useAuthRequest";
import {condition, request} from "../../../../../res/rest/restRequest";
import axios from "axios";
import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";

const useOverPayment = () => {

    const { session } = useSelector(
        state => state.local.auth
    )
    const {
        businessPartner
    } = useBusinessPartner()
    const {
        loading,
        result,
        sendIt
    } = useAuthRequest();

    const getOverdue = () => {
        const req = request.model("web_bp_overdue_v")
            .select("overdue")
            .filter(condition.eq("c_bpartner_ID", session.bPartnerID))

        const sendReq = async (authSession) => {
            const resp = await axios.get(req.buildRequest(env.API_URL), api.auth(authSession));
            return resp.data.records[0];
        }

        sendIt(sendReq);
    }

    useValueChangeListener(getOverdue, [session], []);

    const overDue = result?.overdue;
    const requiresAdditionalPayment = overDue > 0
        && (businessPartner?.getPaymentRule()?.id === "K"
            || businessPartner?.getCreditStatus() === "S");

    const calculateOverpayment = (orderCost) => {
        const orderFraction = orderCost * (0.15);
        const overPayment = Math.min(orderFraction, overDue);
        return parseFloat(overPayment.toFixed(2));
    }

    return {
        loading,
        requiresAdditionalPayment,
        calculateOverpayment
    }
}

export default useOverPayment;
