import useAuthRequest from "../request/useAuthRequest";
import useValueChangeListener from "../general/useValueChangeListener";
import {condition, request} from "../../../../res/rest/restRequest";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import useDeliveryCutoff from "../../../../components/state/account/useDeliveryCutoff";

const formatDays = (dates) => {
    const C_BPartner_Location_ID = {
        id: 0,
        identifier: "Sutherland Brothers Collection",
    }

    // console.log("coll", dates)

    return dates.map(d => ({
        C_BPartner_Location_ID,
        available_date: d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate(),
    }))
}

const getNext60Weekdays = () => {
    const weekdays = [];
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    for (let i = 0; i < 60; i++) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            weekdays.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return weekdays;
}

const isBeforeCutOff = (cutOff, avDate) => {
    if(!cutOff) return false;

    const now = new Date();
    const then = new Date(avDate.available_date);
    then.setHours(now.getHours());
    then.setMinutes(then.getMinutes());

    return then < cutOff;
}

const collectionDays = formatDays(getNext60Weekdays());

const useDeliveryDates = ({ bPartnerID, bPartnerLocationID, top, maxDate, autoLoad=true, forPickUp }) => {

    const {
        loading,
        error,
        result,
        sendIt,
    } = useAuthRequest();

    const cutoff = useDeliveryCutoff();

    const fetchDates = () => {
        if((!bPartnerID && !bPartnerLocationID) || forPickUp) {
            console.log("returnoin")
            return;
        }

        const req = request.model("sales_available_delivery_date")
            .select("c_salesRegion_ID", "c_bpartner_Location_ID", "available_date");

        if(bPartnerID)
            req.filter(condition.eq("c_bpartner_ID", bPartnerID));

        if(bPartnerLocationID)
            req.filter(condition.eq("c_bpartner_location_ID", bPartnerLocationID));

        if(maxDate)
            req.filter(condition.lessThan("available_date", "'" + idempiereDate.getDateString(maxDate) + "'"))

        if(top)
            req.top(top)

        sendIt(req.get);
    };

    const autoFetch = () => {
        if(autoLoad) fetchDates();
    }

    useValueChangeListener(autoFetch, [bPartnerID, bPartnerLocationID, autoLoad], [0,0]);

    const cutOffFilter = d => !isBeforeCutOff(cutoff, d);
    const availableDates = forPickUp ? collectionDays.filter(cutOffFilter) : result?.filter(cutOffFilter);

    console.log(result)

    return {
        loading :  loading,
        error,
        fetchDates,
        availableDates,
        sendIt
    }
}

export default useDeliveryDates;
