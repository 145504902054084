import ReactGA from "react-ga4";

const getPaymentMessage = (typeID, cardDetails) => {
    if(typeID === "K") {
        if(cardDetails.CustomerPaymentProfileID) {
            return "Payment by stored card";
        } else {
            return "Payment by entered card"
        }
    } else if(typeID === "D") {
        return "Payment by direct debit";
    } else {
        return "Payment with account"
    }
}

export const trackPaymentType = (typeID, cardDetails) => {
    try {
        const event_name = getPaymentMessage(typeID, cardDetails)
        const eventParams = {
            event_name,
            category: "Payment",
            label: typeID,
        };

        ReactGA.event(event_name, eventParams);
    } catch (e) {
        console.log("Failed to track payment type", e);
    }
}
