import {useDispatch, useSelector} from "react-redux";
import {useMemo, useState} from "react";
import AddressDisplay from "../../account/address/AddressDisplay";
import AddressConfigure from "../../account/address/AddressConfigure";
import {updateOrder} from "../../../redux/action/cartActions";
import TextArea from "../../general/input/TextArea";
import RadioSelect from "../../general/input/RadioSelect";
import useDeliveryCutoff from "../../state/account/useDeliveryCutoff";
import useValueChangeListener from "../../../newStructure/services/state/general/useValueChangeListener";
import useBusinessLocations from "../../../newStructure/services/state/account/useBusinessLocations";
import useDeliveryDates from "../../../newStructure/services/state/delivery/useDeliveryDates";
import LoadingSwitcher from "../../../newStructure/component/general/loading/LoadingSwitcher";
import Calendar from "../../../newStructure/component/general/calendar/Calendar";
import {cronDays} from "../../../res/dataServices/cronDays";

const deliveryOptions = {
    deliver: {
        type:'deliver',
        displayText:'Deliver',
        id: 'D'
    },
    collect: {
        type:'collect',
        displayText:'Collect',
        id: 'P'
    }
}

export const AddressInput = ({ selectedAddress, locations, loading, error, onSelect, setCheckoutBlock }) => {

    const [selecting, setSelecting] = useState(locations?.length > 1);
    const [selected, setSelected] = useState(selectedAddress);
    useValueChangeListener(() => setCheckoutBlock(selecting ? "Please select address" : null), selecting, false);

    const toggleSelecting = () => {
        setSelecting(!selecting);
        if(!selected) setCheckoutBlock(null);
    }

    if(selected !== selectedAddress) {
        setSelected(selectedAddress);
        if(selecting) setSelecting(false);
    }

    const onAddressSelected = (address) => {
        const selectedSameAsSet = selectedAddress && (selectedAddress.id === address.id);
        if(selectedSameAsSet) {
            setSelecting(false);
        } else {
            onSelect(address);
        }
    }

    if(!selecting) {
        return (
            <>
                {selectedAddress &&
                    <AddressDisplay
                        address={selectedAddress}
                        loading={loading}
                        error={error} />
                }
                <p className="clickable-text" style={{color: "var(--secondary)", fontWeight: "bold"}} onClick={toggleSelecting}>Change Address</p>
            </>
        )
    } else {
        return (
            <AddressConfigure
                addresses={locations}
                onSelect={onAddressSelected}
            />
        )
    }
}

const getAvailableCollectionDates = (cutoff) => {
    return cronDays.findDatesWithCronPatternAndCutOff("0 0 * * 1-5", 120,
        cutoff?.getHours(), cutoff?.getMinutes())
}

const DeliveryDate = ({session, address, cart}) => {

    const dispatch = useDispatch();
    const cutOff = useDeliveryCutoff();

    const { loading, availableDates : deliveryDates } = useDeliveryDates({ bPartnerLocationID: address?.id });
    const justTheDates = useMemo(() => deliveryDates ? deliveryDates.map(d => new Date(d.available_date)) : [], [deliveryDates]);
    const availableDates = cart.isForDelivery() ? justTheDates : getAvailableCollectionDates(cutOff);

    const datePromised = cart.getDatePromised();

    const onDatePromisedChanged = (date) => {
        if(date !== null) date.setHours(12); // Stops time zone from affecting the date
        const toChange = {"DatePromised": date};
        const orderID = cart.id;
        dispatch(updateOrder({session, orderID, toChange}))
    }

    const isDateOk = cart.isValidDeliveryDate(availableDates, datePromised, cutOff?.getHours(), cutOff?.getMinutes());
    if(!isDateOk && !cart.error.order && !cart.loading.order && availableDates?.length > 0) {
        if(availableDates.length > 0) {
            console.log("auto updating order date");

            const getNextAvailableDate = () => {
                for(const d of availableDates) {
                    if(cart.isValidDeliveryDate(availableDates, d, cutOff?.getHours(), cutOff?.getMinutes())) {
                        return d;
                    }
                }
            }

            const nextDate = getNextAvailableDate();
            if(nextDate) {
                onDatePromisedChanged(nextDate);
            }
        }
    }

    const canDeliver = availableDates && availableDates.length > 0;

    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(minDate.getMonth() + 6);

    return (
        <>
            <p className="form-sub-heading">Delivery Date</p>
            <LoadingSwitcher loading={loading}>
                {!canDeliver && <p className="form-warning">This is address is not set up for delivery</p>}
                {canDeliver &&
                    <Calendar
                        minDate={minDate}
                        maxDate={maxDate}
                        whiteList={availableDates}
                        selectedDate={datePromised}
                        onDateClick={onDatePromisedChanged}
                    />
                }
            </LoadingSwitcher>
        </>
    )
}

const InputDelivery = ({ cart, setCheckoutBlock }) => {
    const dispatch = useDispatch();
    const { session } = useSelector(
        state => state.local.auth
    )

    const { locations, loading, error } = useBusinessLocations();

    const selectedDeliveryOption = (() => {
        const deliverVia = cart.order.DeliveryViaRule.id;
        if(deliverVia === 'P') return deliveryOptions.collect
        else if(deliverVia === 'D') return deliveryOptions.deliver
        else return null;
    })();

    const orderID = cart.order.id;
    const address = cart.getAddress(locations);
    const deliveryNote = cart.order.X_DeliveryNote;

    const onAddressChanged = (address) => {
        const toChange = {"C_BPartner_Location_ID": {"id": address.id}};
        dispatch(updateOrder({session, orderID, toChange}))
    }

    const onDeliveryViaChanged = (deliveryVia) => {
        const optionID = deliveryVia.id;
        if(optionID !== selectedDeliveryOption) {
            const toChange = {"DeliveryViaRule" : {"id": optionID}}
            dispatch(updateOrder({session, orderID, toChange}))
        }
    }

    const onDeliveryNoteChanged = (event) => {
        const input = event.target.value;
        if(input !== deliveryNote) {
            const toChange = {"X_DeliveryNote": input}
            dispatch(updateOrder({session, orderID, toChange}))
        }
    }

    return (
        <>
            <AddressInput
                selectedAddress={address}
                locations={locations}
                loading={loading}
                error={error}
                onSelect={onAddressChanged}
                setCheckoutBlock={setCheckoutBlock}
            />
            <p className="form-sub-heading">Delivery Method</p>
            <RadioSelect
                options={[deliveryOptions.deliver, deliveryOptions.collect]}
                optionToText={o => o.displayText}
                onOptionSelected={onDeliveryViaChanged}
                defaultOption={selectedDeliveryOption}
            />
            {selectedDeliveryOption === deliveryOptions.collect &&
                <p style={{margin: "8px 0", fontWeight: "bold"}} className="form-warning">Your order will need to be picked at the Wick Depot</p>
            }
            <DeliveryDate
                session={session}
                address={address}
                cart={cart}
            />
            <p className="form-sub-heading">Delivery Instructions</p>
            <p className="form-text-block">Please Note: An order can only be delivered to one location. You will need to create an additional order
                if you require multiple delivery points.</p>
            <TextArea
                defaultValue={deliveryNote}
                onBlur={onDeliveryNoteChanged}
            />
        </>
    )

}

export default InputDelivery;
