import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";

const CartLine = ({type, price, bold}) => {

    return (
        <div className={"cart-summary-line" + (bold ? " b" : "")}>
            <p className="cart-summary-item">{type}</p>
            <p className="cart-summary-price">{price}</p>
        </div>
    )
}

const CartSummary = ({ checkout }) => {

    return (
        <div className="cart-section">
            {checkout.getCharges().map((e,i) =>
                <CartLine
                    key={i}
                    type={e.C_Charge_ID.Name}
                    price={formatDisplayPrice(e.LineNetAmt)}
                />
            )}
            <CartLine type="Guide Price" price={checkout.getGuidePrice()} />
            <CartLine type="Total" price={checkout.getTotalPrice()} bold />
        </div>
    )
}

export default CartSummary;
