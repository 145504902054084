import useWebProduct from "../../../../../components/state/useWebProduct";
import {ProductPurchaseInfo} from "../../../../../res/product/productPurchaseInfo";
import ProductInput from "../../../product/ProductInput";
import BinIcon from "../../../../../res/icon/BinIcon";

const CartLineInput = ({ product }) => {

    const deleteLine = () => {
        product.setQuantityEntered(0);
    }

    return (
        <div className="cart-line-input">
            <ProductInput
                product={product}
                includeDeleteButton
            />
            <div className="cart-line-delete" onClick={deleteLine}>
                <BinIcon />
            </div>
        </div>
    )
}

const CartLine = ({ line }) => {

    const { webProduct } = useWebProduct({orderLineID: line.id, product: line.M_Product_ID});
    const product = ProductPurchaseInfo.getCartDisplayProduct(webProduct);


    return (
        <div className="cart-line-grid">
            <p className="cart-line-h2">{product.getName()} {product.getCaseDescription()}</p>
            <p className="cart-line-h2 cart-h-r">£{product.getUnitPrice()}</p>
            <CartLineInput product={product} />
            <p className="cart-line-h2 cart-h-r">{product.getDisplayPrice()}</p>
        </div>
    )
}

export default CartLine;
