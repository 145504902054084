import {condition, request} from "../../../../../res/rest/restRequest";
import useAuthRequest from "../../../../services/state/request/useAuthRequest";
import {env} from "../../../../../res/config/env";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";

const getBPMinSpend = async (session) => {
    return request.model("c_bpartner")
        .select("X_Freeshipment_Amount")
        .filter(
            condition.eq("c_bpartner_ID", session.bPartnerID),
            condition.greaterThan("X_Freeshipment_Amount", 0)
        )
        .get(session);
}

const getClientMinSpend = (session) => {
    return request.model("web_client_info")
        .select("x_freeshipment_amount")
        .filter(
            condition.eq("ad_client_ID", env.AD_CLIENT_ID),
        )
        .get(session);
}

const getCategoryMinSpend = async (session) => {
    return request.model("m_product_category")
        .select("name", "x_freeshipment_amount")
        .filter(condition.greaterThan("x_freeshipment_amount", 0))
        .get(session);
}

const fetchMinSpend = async (session) => {
    const minSpend = {};

    const bpMinSpend = (await getBPMinSpend(session))[0];
    if(bpMinSpend) {
        minSpend.standard = bpMinSpend;
    } else {
        minSpend.standard = (await getClientMinSpend(session))[0];
    }

    minSpend.category = await getCategoryMinSpend(session);

    return minSpend;
}

const useMinSpend = () => {

    const {
        loading,
        error,
        sendIt,
        result,
    } = useAuthRequest();

    const getMinSpend = () => {
        sendIt(fetchMinSpend)
    }

    useValueChangeListener(getMinSpend, [1], [0]);

    return {
        loading,
        error,
        minSpend: result,
        getMinSpend,
    }
}

export default useMinSpend;
