import PaymentProcessor from "../../../../../components/payment/PaymentProcessor";
import {useState} from "react";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import Dialog from "../../../general/dialog/Dialog";
import Popup from "../../../../../components/general/popup/Popup";
import DialogTitle from "../../../general/dialog/DialogTitle";
import DialogMessage from "../../../general/dialog/DialogMessage";
import DialogButtonContainer from "../../../general/dialog/DialogButtonContainer";
import DialogButton from "../../../general/dialog/DialogButton";
import useSession from "../../../../services/state/session/useSession";

const CartPaymentProcessor = ({ checkout }) => {

    const { sessionData : session } = useSession();
    const [error, setError] = useState(null);
    const checkoutError = checkout.getPaymentError();
    useValueChangeListener(() => setError(checkoutError), [checkoutError]);

    return (
        <>
            {error &&
                <Popup>
                    <Dialog>
                        <DialogTitle>{error[0]}</DialogTitle>
                        <DialogMessage>{error[1]}</DialogMessage>
                        <DialogButtonContainer>
                            <DialogButton
                                onClick={() => setError(null)}
                            >
                                OK
                            </DialogButton>
                        </DialogButtonContainer>
                    </Dialog>
                </Popup>
            }
            <PaymentProcessor session={session} />
        </>
    )
}

export default CartPaymentProcessor;
