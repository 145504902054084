import RadioGroup from "../../../general/input/radio/RadioGroup";
import Radio from "../../../general/input/radio/Radio";
import InputWithFormatAndValidation from "../../../../../components/general/input/InputWithFormatAndValidation";
import {paymentValidator} from "../../../../../components/payment/paymentValidator";
import React, {useState} from "react";
import useCreditCardInput from "../../../payment/useCreditCardInput";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import {useDispatch, useSelector} from "react-redux";
import {onCardInput} from "../../../../../redux/slice/paymentSlice";
import {cardInfo} from "../../../../../res/dataServices/cardInfo";
import useBanking from "../../../../services/state/account/useBanking";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";
import CardDisplay from "../../../../../components/payment/CardDisplay";

const splitName = (value) => {
    const namesArray = value.trim().split(" ");
    const lastName = namesArray.length > 1 ? namesArray.pop() : '';
    const firstName = namesArray.join(" ");
    return [firstName, lastName];
}

const getCardID = (bank) => {
    return bank.CreditCardType.id
        + "_" + bank.CreditCardNumber
        + "_" + bank.CreditCardExpMM
        + "_" + bank.CreditCardExpYY
        + "_" + bank.A_City
        + "_" + bank.A_Street
        + "_" + bank.A_Zip
        + "_" + bank.A_Name
}

const getSavedCards = (banking) => {

    const isCardExpired = (expireMonth, expireYear) => {
        const currentDate = new Date();
        const expiryDate = new Date(expireYear, expireMonth -1)
        return expiryDate < currentDate;
    }

    const sortedBanks = banking ? banking.map(i => i).sort((thisBank, thatBank) =>
        idempiereDate.getDate(thisBank.Created) > idempiereDate.getDate(thatBank.Created)
    ) : [];

    const saved = {};
    for(const bank of sortedBanks) {
        if(bank.CreditCardNumber && bank.CreditCardNumber.length > 0) {
            if(!isCardExpired(bank.CreditCardExpMM, bank.CreditCardExpYY)) {
                saved[getCardID(bank)] = bank;
            }
        }
    }

    return Object.values(saved);
}

const NewCardInput = ({ checkout }) => {

    const dispatch = useDispatch();
    const onChange = (arg) => {
        checkout.setCardDetails(arg);
        dispatch(onCardInput({data: arg}));
    }
    const defaultCard = {};

    const {
        onNameChange,
        onNumberChange,
        onCvvChange,
        onExpiryChange,
        getDefaultName
    } = useCreditCardInput({onChange, defaultCard});

    return (
        <div className="card-detail-input">
            <div style={{gridArea: "name"}}>
                <InputWithFormatAndValidation
                    hint="Name On Card"
                    validateFunction={paymentValidator.validateName}
                    onInput={onNameChange}
                    defaultValue={getDefaultName()}
                />
            </div>
            <div style={{gridArea: "num"}}>
                <InputWithFormatAndValidation
                    hint="Card Number"
                    formatFunction={paymentValidator.formatCardNumber}
                    validateFunction={paymentValidator.validateCardNumber}
                    onInput={onNumberChange}
                    defaultValue={defaultCard ? defaultCard.cardNumber : ''}
                />
            </div>
            <div style={{gridArea: "exp"}}>
                <InputWithFormatAndValidation
                    hint="Expiry (mm/yy)"
                    formatFunction={paymentValidator.formatExpiry}
                    validateFunction={paymentValidator.validateExpiry}
                    onInput={onExpiryChange}
                    defaultValue={defaultCard ? defaultCard.expireMonth + defaultCard.expireYear : ''}
                />
            </div>
            <div style={{gridArea: "cvv"}}>
                <InputWithFormatAndValidation
                    hint="Security Code (cvv)"
                    formatFunction={paymentValidator.formatCvv}
                    validateFunction={paymentValidator.validateCvv}
                    onInput={onCvvChange}
                    defaultValue={defaultCard ? defaultCard.cvv : ''}
                />
            </div>
        </div>
    )
}

const SelectCard = ({ checkout }) => {

    const dispatch = useDispatch();

    const { banking, loading } = useBanking();
    const savedCards =  getSavedCards(banking);
    const { cardInput } = useSelector(
        state => state.session.payment
    )
    const selectedCard = cardInput && cardInput.bankID ? cardInput.bankID : 0;

    const onCardChange = (arg) => {
        checkout.setCardDetails(arg);
        dispatch(onCardInput({data: arg}));
    }

    const onCardSelected = (bank) => {
        const [firstName, lastName] = splitName(bank.A_Name);
        onCardChange({
            bankID: bank.id,
            customerPaymentProfileID: bank.CustomerPaymentProfileID,
            expireMonth: bank.CreditCardExpMM,
            expireYear: bank.CreditCardExpYY,
            cardType: cardInfo.getCodeForIdempiereID(bank.CreditCardType.id),
            firstName,
            lastName,
            email: bank.A_Email
        })
    }

    return (
        <LoadingSwitcher loading={loading}>
            {savedCards && savedCards.length > 0 &&
                <RadioGroup>
                    {savedCards.map(c =>
                        <Radio
                            onSelected={() => onCardSelected(c)}
                            isSelected={selectedCard === c.id}>
                            <CardDisplay bank={c} />
                        </Radio>
                    )}
                </RadioGroup>
            }
            {(!savedCards || savedCards.length === 0) &&
                <p>You currently have no saved cards</p>
            }
        </LoadingSwitcher>
    )

}

const CardInput = ({ checkout }) => {

    const [ useSavedCard, setUseSavedCard ] = useState(true);

    return (
        <>
            <p className="cart-h">Cards</p>
            <RadioGroup>
                <Radio
                    title="Saved Card"
                    onSelected={() => setUseSavedCard(true)}
                    isSelected={useSavedCard}
                />
                <Radio
                    title="New Card"
                    onSelected={() => setUseSavedCard(false)}
                    isSelected={!useSavedCard}
                />
            </RadioGroup>
            {useSavedCard ?
                <SelectCard checkout={checkout} /> :
                <NewCardInput checkout={checkout} />
            }
        </>
    )

}

const CartPayment = ({ checkout }) => {

    const paymentOption = checkout.getPaymentType();

    return (
        <>
            <p className="cart-h">Payment Type</p>
            <LoadingSwitcher loading={checkout.isBusinessPartnerInfoLoading()}>
                <RadioGroup>
                    {checkout.getPaymentOptions().map((e,i) =>
                        <Radio
                            key={i}
                            title={e.displayText}
                            isSelected={paymentOption === e.type}
                            onSelected={() => checkout.updatePaymentType(e.type)}
                        />
                    )}
                </RadioGroup>
                {paymentOption === 'K' &&
                    <CardInput checkout={checkout} />
                }
            </LoadingSwitcher>
        </>
    )

}

export default CartPayment;
