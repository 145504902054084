
export function formatDisplayPrice(number, isAccounting=false) {
    if(isNaN(number)) return "---"
    // Convert number to a fixed decimal with 2 digits
    const fixedNumber = number.toFixed(2);

    // Add thousands separator (comma) if applicable
    const parts = fixedNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const isNegative = parts[0].startsWith("-");
    parts[0] = parts[0].replace("-", "");

    const price = `£${parts.join(".")}`

    if(isNegative) {
        if(isAccounting) {
            return "(" + price + ")";
        } else {
            return "-" + price;
        }

    } else {
        return price;
    }
}

export const priceService = {
    formatDisplayPrice
}
