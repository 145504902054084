
const BinIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#EE1D26"/>
            <rect x="5" y="7" width="14" height="1" rx="0.2" fill="white"/>
            <mask id="path-3-inside-1_1067_2677" fill="white">
                <rect x="6.5" y="7" width="11" height="12" rx="0.2"/>
            </mask>
            <rect x="6.5" y="7" width="11" height="12" rx="0.2" stroke="white" stroke-width="2"
                  mask="url(#path-3-inside-1_1067_2677)"/>
            <rect x="9" y="10" width="1" height="6" rx="0.2" fill="white"/>
            <rect x="11.5" y="10" width="1" height="6" rx="0.2" fill="white"/>
            <rect x="14" y="10" width="1" height="6" rx="0.2" fill="white"/>
            <path
                d="M8.5 7.49996C8.5 7.49996 9.78407 5.14798 10 4.99996C10.1605 4.88992 13.856 4.90168 14 4.99996C14.1441 5.09829 15.5 7.49996 15.5 7.49996"
                stroke="white"/>
        </svg>
    )

}

export default BinIcon;
