import '../cart.css'
import CartLine from "../input/CartLine";
import CartSummary from "../info/CartSummary";
import {Button} from "../../../../../components/general/input/Button";
import CartDeliveryNote from "../info/CartDeliveryNote";
import CartDeliveryMessage from "../info/CartDeliveryMessage";

const CartReview = ({ onNext, checkout }) => {

    return (
        <div className="cart-container">
            <div className="cart-header">
                <p className="cart-h1">Your Cart</p>
                <CartDeliveryNote
                    orderLocation={checkout.getBPartnerLocation()}
                    availableDates={checkout.getAvailableDates()}
                    loading={checkout.isDeliveryDatesLoading()}
                    forPickUp={checkout.getDeliveryViaRule() === 'P'}
                />
            </div>
            <div className="cart-content">
                <div className="cart-line-container">
                    <div className="cart-line-grid">
                        <p className="cart-line-h1">Item</p>
                        <p className="cart-line-h1 cart-h-r">Price</p>
                        <p className="cart-line-h1">Quantity</p>
                        <p className="cart-line-h1 cart-h-r">Amount</p>
                    </div>
                    {checkout.lines.map((line, i) =>
                        <CartLine key={i} line={line}/>
                    )}
                </div>
                <div className="cart-column cart-r">
                    <div className="cart-section cart-r">
                        <p className="cart-h2">Summary</p>
                        <CartSummary checkout={checkout} />
                    </div>
                    <div className="cart-delivery cart-r">
                        <p className="cart-h2">Delivery</p>
                        <CartDeliveryMessage checkout={checkout} />
                    </div>
                    <div className="cart-r">
                        <p className="cart-h2">Checkout</p>
                        <Button onClick={onNext}>Proceed to Checkout</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartReview;
