import AccountNav from "../../../../logic/navigation/accountNav";
import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import useOrders from "../../../../services/state/account/useOrders";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import usePrint from "../../../../../components/general/output/usePrint";
import LineOrder from "../../order/detailLines/LineOrder";
import LinkTextButton from "../../../navigation/link/LinkTextButton";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import React from "react";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";
import {format} from "date-fns";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";
import './order.css';
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, AccountNav.items.order];
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.orders, VendorNav.items.order];

const OrderDetailContent = ({ id }) => {

    const {
        loading,
        orders,
        getOrders
    } = useOrders();

    const getTheOrder = () => {
        getOrders({
            documents: [id],
            includeOrderLines: true
        })
    };

    useValueChangeListener(getTheOrder, [id], []);

    const order = orders && orders.length > 0 ? orders[0] : null;
    const lines = order?.c_orderline?.sort((a,b) => a.Line - b.Line);
    const orderType = order?.IsSOTrx ? "Sales Order" : "Purchase Order";
    const jsDateOrdered = order ? idempiereDate.getDate(order.DateOrdered) : null;
    const formattedOrdered = order ? format(jsDateOrdered, "dd/MM/yy") : null;
    const displayPrice = order ? formatDisplayPrice(order.GrandTotal) : null;
    const documentNo = order ? (!order.IsSOTrx && order.POReference ? order.POReference : order.DocumentNo) : null;

    const printUrl = request.print(api.MODEL.C_ORDER, order?.id).buildRequest(env.API_URL)
    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({url: printUrl});

    return (
        <div className="acct-content">
            <LoadingSwitcher loading={loading}>
                <p className="acct-h1">Order {order?.DocumentNo}</p>
                <p className="acct-h2">{orderType}</p>
                <div className="header-detail" style={{maxWidth: 600}}>
                    <div>
                        <p className="acct-line-h2">Order No</p>
                        <p className="acct-line-h3">{documentNo}</p>
                    </div>
                    <div>
                        <p className="acct-line-h2">Ordered</p>
                        <p className="acct-line-h3">{formattedOrdered}</p>
                    </div>
                    <div>
                        <p className="acct-line-h2">Location</p>
                        <p className="acct-line-h3">{order?.C_BPartner_Location_ID.identifier}</p>
                    </div>
                    <div>
                        <p className="acct-line-h2 num">Total</p>
                        <p className="acct-line-h3 num">{displayPrice}</p>
                    </div>
                </div>
            </LoadingSwitcher>
            <LoadingSwitcher loading={loadingPDF}>
                <LinkTextButton
                    onClick={fetchAndPrintIt}
                    className="header-link"
                >
                    Print Order
                </LinkTextButton>
            </LoadingSwitcher>
            <p style={{marginTop: "32px", marginBottom: "12px"}} className="acct-h1">Order Lines</p>
            <LoadingSwitcher loading={loading}>
                <div className="detail-line">
                    <p className="acct-line-h2">Line No.</p>
                    <p className="acct-line-h2">Product</p>
                    <p className="acct-line-h2 num">Quantity</p>
                    <p className="acct-line-h2 num" style={{paddingLeft: 20}}>Total</p>
                    {lines && lines.map((e, i) => (
                        <LineOrder
                            key={i}
                            orderLine={e}
                            highlight={i%2===1}
                        />
                    ))}
                </div>
            </LoadingSwitcher>
        </div>
    )
}

const OrderDetailLayout = ({id}) => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    const breadcrumb = [...breadcrumbTrail, {name: id, nav: breadcrumbTrail[2].nav + "/" + id, selected: true}];

    return (
        <AccountLayout breadcrumbTrail={breadcrumb}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <OrderDetailContent id={id}/>
        </AccountLayout>
    )
}

export default OrderDetailLayout;
