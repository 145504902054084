
const compareDates = (date1, date2) => {
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    if (year1 > year2) return 1;
    if (year1 < year2) return -1;
    if (month1 > month2) return 1;
    if (month1 < month2) return -1;
    if (day1 > day2) return 1;
    if (day1 < day2) return -1;

    return 0;
}


const getDaysBetweenDates = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

const getMonthBounds = (year, month) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return [firstDay, lastDay]
}

const getWeekBounds = (date) => {
    const givenDate = new Date(date);
    const dayOfWeek = givenDate.getDay();
    const firstDay = new Date(givenDate);
    const lastDay = new Date(givenDate);

    firstDay.setDate(givenDate.getDate() - dayOfWeek); // Start of the week
    lastDay.setDate(givenDate.getDate() + (6 - dayOfWeek)); // End of the week

    return [firstDay, lastDay];
}

const getCalendarBounds = (year, month) => {
    const [firstMonthDay, lastMonthDay] = getMonthBounds(year, month);
    const [ firstDay ] = getWeekBounds(firstMonthDay);
    // eslint-disable-next-line
    const [ ignore, lastDay ] = getWeekBounds(lastMonthDay);

    const daysBetween = getDaysBetweenDates(firstDay, lastDay);
    if(daysBetween < 40) {
        const toAdd = daysBetween === 27 ? 14 : 7;
        lastDay.setDate(lastDay.getDate() + toAdd);
    }

    return [firstDay, lastDay];
}

const getDateRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    while (start <= end) {
        dateArray.push(new Date(start));
        start.setDate(start.getDate() + 1); // Move to the next day
    }

    return dateArray;
}

const getCalendarDates = (year, month) => {
    const [firstDay, lastDay] = getCalendarBounds(year, month);
    return getDateRange(firstDay, lastDay);
}

const getDisplayDate = (date) => {

    const options = {
        weekday: 'long', // Full weekday name (e.g., Wednesday)
        month: 'long',   // Full month name (e.g., November)
        day: 'numeric'  // Day of the month (e.g., 27)
    };

    const formattedDate = date.toLocaleDateString('en-GB', options);

    const day = date.getDate();
    let daySuffix = 'th';
    if (day >= 11 && day <= 13) {
        daySuffix = 'th'; // Special case for 11th, 12th, and 13th
    } else {
        switch (day % 10) {
            case 1:  daySuffix = 'st'; break;
            case 2:  daySuffix = 'nd'; break;
            case 3:  daySuffix = 'rd'; break;
            default: daySuffix = 'th';
        }
    }

    return formattedDate.replace(day, day + daySuffix);
}

const isSameDay = (thisDate, thatDate) => {
    return compareDates(thisDate, thatDate) === 0;
}

const getUkDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

const DateUtil = {
    compareDates,
    getDaysBetweenDates,
    getMonthBounds,
    getWeekBounds,
    getCalendarBounds,
    getDateRange,
    getCalendarDates,
    getDisplayDate,
    getUkDate,
    isSameDay
}

export default DateUtil;
