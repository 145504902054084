import {Button} from "../../../../../components/general/input/Button";
import usePrint from "../../../../../components/general/output/usePrint";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import {reportError} from "../../../../../redux/slice/errorReportSlice";
import {useDispatch} from "react-redux";

const CartConfirmationPrint = ({ checkout }) => {

    const dispatch = useDispatch();
    const { loading, fetchAndPrintIt } = usePrint({
        url: request.print(api.MODEL.C_ORDER, checkout.getOrderID()).buildRequest(env.API_URL),
        onError: (e) => dispatch(reportError({
            code: "shipmentPrintError",
            error: e && e.message ? e.message : "failed to fetch report"
        }))
    });

    const onPrint = () => {
        if(!loading) {
            fetchAndPrintIt();
        }
    }

    return (
        <>
            <Button
                className={loading ? "inactive" : ""}
                onClick={onPrint}
            >
                Print
            </Button>
        </>
    )

}

export default CartConfirmationPrint;
