import Radio from "../../../general/input/radio/Radio";
import RadioGroup from "../../../general/input/radio/RadioGroup";

const CartDeliveryMethod = ({ checkout }) => {

    const selected = checkout.getDeliveryViaRule();

    const onRadioClick = (deliveryVia) => () => checkout.updateDeliveryVia(deliveryVia);

    return (
        <div className="cart-sub-section">
            <p className="cart-h">Delivery Via</p>
            <RadioGroup>
                <Radio
                    title="Delivery"
                    isSelected={'D' === selected}
                    onSelected={onRadioClick('D')}
                />
                <Radio
                    title="Collection"
                    isSelected={'P' === selected}
                    onSelected={onRadioClick('P')}
                />
            </RadioGroup>
            {selected === 'P' &&
                <p className="cart-warning">Your order will need to be picked from the Wick Depot</p>
            }
        </div>
    )

}

export default CartDeliveryMethod;
