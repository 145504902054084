
const RadioGroup = ({children}) => {

    return (
        <div className="radio-group">
            {children}
        </div>
    )
}

export default RadioGroup;
