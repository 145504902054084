import {useState} from "react";
import Popup from "../../../../../components/general/popup/Popup";
import Dialog from "../../../general/dialog/Dialog";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import DialogTitle from "../../../general/dialog/DialogTitle";
import DialogMessage from "../../../general/dialog/DialogMessage";
import DialogButtonContainer from "../../../general/dialog/DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "../../../general/dialog/DialogButton";

const CartCheckoutError = ({ checkout }) => {

    const [error, setError] = useState(null);
    const checkoutError = checkout.getCheckoutError();
    useValueChangeListener(() => setError(checkoutError), [checkoutError]);

    if(!error) return;

    const [ title, message ] = error;

    return (
        <Popup>
            <Dialog>
                <DialogTitle>{title}</DialogTitle>
                <DialogMessage>{message}</DialogMessage>
                <DialogButtonContainer>
                    <DialogButton
                        onClick={() => setError(null)}
                        type={DIALOG_BUTTON_TYPE.positive}
                    >
                        OK
                    </DialogButton>
                </DialogButtonContainer>
            </Dialog>
        </Popup>
    )


}

export default CartCheckoutError;
