import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import Calendar from "../../../general/calendar/Calendar";
import DateUtil from "../../../general/calendar/dateUtil";
import {useState} from "react";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";

const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 60);

const DeliveryDateSelection = ({ checkout, justTheDates, onBack }) => {

    const deliveryVia = checkout.getDeliveryViaRule();
    const datePromised = checkout.getDatePromised();
    const typeText = deliveryVia === 'D' ? "Delivery" : "Collection";

    const onDatePromisedChanged = (date) => {
        checkout.updateDatePromised(date, onBack)
    }

    return (
        <>
            <LoadingSwitcher loading={checkout.isDeliveryDatesLoading()}>
                {justTheDates && justTheDates.length > 0 &&
                    <>
                        <p className="cart-h">Please select {typeText} date</p>
                        <Calendar
                            maxDate={justTheDates[justTheDates.length - 1]}
                            minDate={justTheDates[0]}
                            whiteList={justTheDates}
                            selectedDate={datePromised}
                            onDateClick={onDatePromisedChanged}
                        />
                    </>
                }
            </LoadingSwitcher>
        </>
    )
}

const DeliveryDateDisplay = ({ checkout, onChange }) => {

    const deliveryVia = checkout.getDeliveryViaRule();
    const datePromised = checkout.getDatePromised();

    const text = (deliveryVia === 'D' ? "Delivery on " : "Collecting on ") + DateUtil.getDisplayDate(datePromised);

    return (
        <div className="cart-info-split">
            <p className="cart-h">{text}</p>
            <p className="clickable-text" onClick={onChange}>Change</p>
        </div>
    )

}

const CartDeliveryDate = ({ checkout }) => {

    const datePromised = checkout.getDatePromised();
    const [ isSelecting, setSelecting ] = useState(false);
    useValueChangeListener(() => setSelecting(false), [datePromised]);
    const deliveryVia = checkout.getDeliveryViaRule();
    const justTheDates = checkout.getAvailableDates()?.map(e => new Date(e.available_date));
    const showWarning = (!justTheDates || justTheDates.length === 0)


    return (
        <div className="cart-sub-section">
            {showWarning &&
                <>
                    {deliveryVia === 'D' ?
                        <p className="cart-warning">Cannot deliver to this address</p> :
                        <p className="cart-warning">Something has gone wrong, no collection dates could be found</p>
                    }

                </>
            }
            {!showWarning &&
                <>
                    {(isSelecting || !datePromised) ?
                        <DeliveryDateSelection
                            checkout={checkout}
                            onBack={() => setSelecting(false)}
                            justTheDates={justTheDates}
                        /> :
                        <DeliveryDateDisplay
                            checkout={checkout}
                            onChange={() => setSelecting(true)}
                        />
                    }
                </>
            }
        </div>
    )
}

export default CartDeliveryDate;
