import {useState} from "react";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import CartAddress from "../info/CartAddress";

const CartDeliverySelection = ({ checkout, onBack }) => {

    return (
        <LoadingSwitcher loading={checkout.isLocationsLoading()}>
            {checkout.getLocations().map((location, i) =>
                <div key={i}>
                    <p className="cart-h">{location.Name}</p>
                    <CartAddress address={location.C_Location_ID} />
                    <p className="clickable-text"
                       onClick={() => checkout.updateAddress(location, onBack)}
                    >
                        Deliver to this address
                    </p>
                </div>
            )}
            <p className="clickable-text" onClick={() => onBack()}>Back</p>
        </LoadingSwitcher>
    )
}

const CartLocation = ({ checkout, onChange }) => {

    const setLocation = checkout.getLocationAndAddress();
    const address = setLocation?.C_Location_ID;

    return (
        <div className="cart-sub-section">
            <LoadingSwitcher loading={checkout.isLocationsLoading()}>
                    <div className="cart-info-split">
                        <p className="cart-h">Delivering to {setLocation?.Name}</p>
                        <p className="clickable-text"
                           onClick={onChange}
                        >
                            Change
                        </p>
                    </div>
                    <CartAddress address={address}/>
            </LoadingSwitcher>
        </div>
    )

}

const CartDeliveryLocation = ({ checkout }) => {

    const [selecting, setSelecting] = useState(false);
    useValueChangeListener(() => setSelecting(false), [checkout.getBPartnerLocationID()])

    return (
        <>
            {!selecting ?
                <CartLocation
                    checkout={checkout}
                    onChange={() => setSelecting(true)}
                /> :
                <CartDeliverySelection
                    checkout={checkout}
                    onBack={() => setSelecting(false)}
                />
            }
        </>
    )

}

export default CartDeliveryLocation;
