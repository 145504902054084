import {useState} from "react";
import {stringUtil} from "../../../res/dataServices/string";
import {cardInfo} from "../../../res/dataServices/cardInfo";

const useCreditCardInput = ({ onChange, defaultCard }) => {

    const [card, setCard] = useState(defaultCard);

    const updateCard = (updatedCard) => {
        if("bankID" in updatedCard) delete updatedCard["bankID"];
        setCard(updatedCard)
        if(onChange) onChange(updatedCard);
    }

    const onNameChange = (event, value) => {
        const namesArray = value.trim().split(" ");
        const lastName = namesArray.length > 1 ? namesArray.pop() : '';
        const firstName = namesArray.join(" ");

        updateCard({
            ...card,
            firstName,
            lastName
        })
    }

    const onNumberChange = (event, value) => {
        const cardNum = stringUtil.removeNonNumbers(value);
        const cardType = cardInfo.detectCardType(cardNum);

        updateCard({
            ...card,
            cardNumber: cardNum,
            type: cardType,
        })
    }

    const onExpiryChange = (event, value) => {
        const parts = value.split("/");

        updateCard({
            ...card,
            expireMonth: parts[0],
            expireYear: parts[1]
        })
    }

    const onCvvChange = (event, value) => {
        updateCard({
            ...card,
            cvv: value
        })
    }

    const getDefaultName = () => {
        return defaultCard ? defaultCard.firstName ?
            defaultCard.firstName + (defaultCard.lastName ? (" " + defaultCard.lastName) : '')
            : '' : '';
    }

    return {
        onNameChange,
        onNumberChange,
        onCvvChange,
        onExpiryChange,
        getDefaultName
    }
}

export default useCreditCardInput;
