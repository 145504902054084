import DateUtil from "../../../general/calendar/dateUtil";
import useSession from "../../../../services/state/session/useSession";

const CartConfirmationHeader = ({ checkout }) => {

    const session = useSession();
    const promisedDate = checkout.getDatePromised();
    const displayPromised = DateUtil.getUkDate(promisedDate);
    const dateOrdered = checkout.getDateOrdered();
    const displayDateOrdered = DateUtil.getUkDate(dateOrdered);

    return (
        <div className="cart-confirmation-header">
            <div>
                <p className="cart-h">Order No.</p>
                <p className="cart-line-h2">{checkout.getDocumentNo()}</p>
            </div>
            <div>
                <p className="cart-h">Promised</p>
                <p className="cart-line-h2">{displayPromised}</p>
            </div>
            <div>
                <p className="cart-h">Ordered</p>
                <p className="cart-line-h2">{displayDateOrdered}</p>
            </div>
            <div>
                <p className="cart-h">Your Ref.</p>
                <p className="cart-line-h2">{checkout.getReference()}</p>
            </div>
            <div>
                <p className="cart-h">Account No.</p>
                <p className="cart-line-h2">{session.getBusinessCode()}</p>
            </div>
        </div>
    )
}

export default CartConfirmationHeader;
